import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import ChrisLaneImage from './assets/chrisbilly.jpg'; // Make sure to have an image in your project
import ChrisPartyImage from './assets/chrisparty.jpg';
import NgageChris from './assets/ngagechris.jpg'
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import './App.css'; 

function EventDetails() {
    const sections = [
            {
              id: 1,
              title: 'A little bit about me and the event...',
              content: [ 
              "Hi, my name is Chris Lane and I'm a DJ and aspiring Radio presenter, my dream is to one day become a presenter for BBC radio. I have been DJing since the age of 12 but have had a passion for music since 4 years of age!",
              "The show will be 55 hours of none stop LIVE radio broadcast. I will be raising money for the amazing MIND UK Charity who do great work for people suffering with Mental Health."
              ],
              imgSrc: ChrisLaneImage,
              imgAlt: 'Event kickoff',
              reverse: false,
              button: {
                text: 'Donate Here!',
                url: 'http://justgiving.com/page/55hours'
              }
            },
            {
              id: 2,
              title: 'Ngage Radio',
              content: [
              "I currently broadcast on Ngage Radio, they have a great team full of supportive people all working together to create the most entertaining radio possible. They will be supporting me during my 55 hour broadcast.",
              ],
              imgSrc: NgageChris,
              imgAlt: 'Ngage Radio',
              reverse: true,
              button: { 
                text: 'Ngage Radio',
                url: 'https://www.ngageradio.co.uk' 
              }
            },
      {
        id: 3,
        title: 'Official Charity Release',
        content: [
        "Chris Lane’s 55 hour LIVE Radio Broadcast. Let’s Do It Again. A record breaking 55 hour LIVE Radio Broadcast for MIND UK, who for over 60 years have worked to improve the lives of people with mental health problems. In recent years Chris’ accomplishments have been 24 and 36 hour broadcasts, both have been very successful raising more than £4000 for MIND UK and the NHS.",
        "Now is the time to set a new Radio fundraising broadcast record, Chris Lane's longest ever show on Friday 31st May, 12 noon through to 7pm on Sunday 2nd June. Chris acknowledges “It’s going to be tough, but with the help and support of Ngage Radio, family and friends, I will accomplish a long awaited dream to beat Chris Moyles 52 hour Radio broadcast that he set on Radio 1 in 2011 for Comic Relief."
        ],
        imgSrc: ChrisPartyImage,
        imgAlt: 'Exclusive Sessions',
        reverse: false,
        button: {
          text: 'Donate now!',
          url: 'http://justgiving.com/page/55hours' 
        }
      },
    ];

    const socialLinks = [
        { name: 'Tiktok', icon: <FaTiktok />, url: 'https://www.tiktok.com/@radiolane?lang=en' },
        { name: 'Instagram', icon: <FaInstagram />, url: 'https://www.instagram.com/radio_lane/?hl=en' },

    ];
  
    return (
        <Container className="mt-5">
          <Row className="justify-content-center">
            <Col xs={12}>
              <h1 className="mb-5 text-center">Radio Lane's 55 Hour Show</h1>
              {sections.map((section) => (
                <Row key={section.id} className={`align-items-center ${section.reverse ? 'flex-row-reverse' : ''} mb-5`}>
                  <Col md={6}>
                    <Image src={section.imgSrc} alt={section.imgAlt} fluid className="rounded-image" />
                  </Col>
                  <Col md={6}>
                    <h2>{section.title}</h2>
                    {section.content.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                        ))}
                    {section.button && (
                      <Button className="dark-grey-button" variant="primary" href={section.button.url}>
                        {section.button.text}
                      </Button>
                    )}
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          {/* This Row is now inside the Container */}
          <Row className="justify-content-center mt-5">
            <Col xs={12} className="text-center">
              <h2>Check out my socials!</h2>
              <div className="social-links">
                {socialLinks.map((link, index) => (
                  <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="social-icon">
                    {link.icon}
                  </a>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      );
                }      
  
  export default EventDetails;
