import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Ensure your custom CSS file includes styles for the new elements

function Homepage() {
  const navigate = useNavigate();

  const handleEventClick = () => {
    navigate('/EventDetails');
  };

  const targetDate = new Date('2024-05-31T11:59:59').getTime();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [countUpTime, setCountUpTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

  function calculateTimeLeft() {
    const difference = targetDate - new Date().getTime();
    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0, countdownEnded: true };
    }
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds, countdownEnded: false };
  }

  function calculateTimeElapsed() {
    const now = new Date().getTime();
    const elapsed = now - targetDate;

    const hours = Math.floor(elapsed / (1000 * 60 * 60));
    const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((elapsed % (1000 * 60)) / 1000);
    return { hours, minutes, seconds };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      if (newTimeLeft.countdownEnded) {
        setCountUpTime(calculateTimeElapsed());
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (timeLeft.countdownEnded) {
      const countUpTimer = setInterval(() => {
        setCountUpTime(calculateTimeElapsed());
      }, 1000);

      return () => clearInterval(countUpTimer);
    }
  }, [timeLeft]);

  useEffect(() => {
    const container = document.querySelector('.starry-background');
    
    for (let i = 0; i < 50; i++) {
      const img = document.createElement('div');
      img.className = 'floating-image';
      container.appendChild(img);
      
      const posX = Math.floor(Math.random() * 100);
      const posY = Math.floor(Math.random() * 100);
      img.style.left = `${posX}vw`;
      img.style.top = `${posY}vh`;
  
      const duration = Math.random() * 5 + 5;
      const delay = Math.random() * -20;
      img.style.animationDuration = `${duration}s`;
      img.style.animationDelay = `${delay}s`;
    }
  }, []);

  return (
    <div className="full-height starry-background">
      <Container className="overlay-content">
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col xs={12} md={8} lg={6} className="text-center">
          <h1 className="display-3 text-white mb-5">Radio Lane</h1>
          <h1 className="display-3 text-white mb-5">The website all about Chris Lane</h1>
            {timeLeft.countdownEnded ? (
              <div>
                <Button variant="warning" size="lg" className="mt-4" onClick={handleEventClick}>
                  Find Out More
                </Button>
              </div>
            ) : (
              <div>
                <p className="text-white fs-1 mb-2">
                  {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                </p>
                <Button variant="warning" size="lg" className="mt-4" onClick={handleEventClick}>
                  Find Out More
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Homepage;
