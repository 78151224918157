import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Components/Homepage'; // Rename your current App component to CountdownPage
import EventDetails from './Components/EventDetails';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/EventDetails" element={<EventDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
